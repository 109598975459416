.top-nav-bar {
  width: 100%;
  background-color: #7a7a7a;
  padding: 10px 50px; 
  display: flex;
  justify-content: center; 
  align-items: center;
  position: sticky;
  position: relative; 
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.top-nav-bar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 55%;
  justify-content: space-between;
  align-items: center;
}

.top-nav-bar li {
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.top-nav-bar li button {
  background-color: #4cb050;
  color: white;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, transform 0.2s; 
  margin: 5px 0;
}

.top-nav-bar li button:hover {
  background-color: #005704;
  transform: translateY(-2px);  
}

.top-nav-bar li button:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
}

.top-nav-bar li button .fa-icon {
  font-size: 1.2em; 
}

