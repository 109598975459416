.admin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;
  background-color: #fafafa; 
  backdrop-filter: blur(8px);
  z-index: 1;
  position: relative;
}

.welcome-message {
  font-size: 2rem; 
  margin-bottom: 20px;
  color: #333; 
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.site-list-container {
  width: 45%;
  padding: 20px 20px 30px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: inset 0px 8px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: height 0.3s ease; 
}


.MuiPaper-root.site-list-container {
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
}

.site-list-title {
  font-size: 1.25rem; 
  margin-bottom: 10px;
  color: #333;
  text-align: left;
}

.site-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.site-list-item {
  cursor: pointer;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}


.site-list-item:hover {
  background-color: #e0f7fa; 
}

.no-sites-message {
  color: #999;
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

.account-info-button {
  background-color: #4cb050 !important;
  color: #fff;
  margin-top: 20px;
  padding: 10px 18px; 
  border: none;
  border-radius: 6px; 
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.account-info-button:hover {
  background-color: #4a884d !important;
  transform: scale(1.02);
}

.sign-out-button {
  background-color: #e71d1a !important;
  color: #fff;
  margin-top: 10px; 
  padding: 10px 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sign-out-button:hover {
  background-color: #cc6b69 !important;
  transform: scale(1.02); 
}

.admin-button-container {
  display: flex; 
  justify-content: center; 
  gap: 10px;
  margin-top: 20px; 
}

@media (max-width: 600px) {
  .site-list-container {
    width: 90%; 
  }
}

