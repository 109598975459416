.sidebar {
  width: 200px;
  background-color: #333;
  color: #fff;
  transition: transform 0.3s ease, width 0.3s ease;
  position: fixed;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar.minimized {
  width: 50px; /* Adjust width for minimized state */
}

.menu-icon {
  cursor: pointer;
  margin: 10px;
  font-size: 20px;
}

.nav-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; /* Ensure this takes up space */
  display: flex;
  flex-direction: column;
}

.nav-menu.hide {
  display: none;
}

.nav-menu ul li {
  padding: 10px;
  cursor: pointer;
}

.nav-menu ul li:hover {
  background-color: #444;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  padding: 0;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: #fff;
}

.spacer {
  flex-grow: 1; /* Pushes the sign-out button to the bottom */
}
