.page-layout {
  background-color: #fff;
}

.revenue-impact-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background-color: #dbdbdb;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.impact-metrics {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 100%; 
  gap: 20px;
  padding: 10px;
}

.metric-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  background-color: #f7f7f7;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.metric-box:hover {
  transform: scale(1.05); 
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); 
}

.metric-icon {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #ff5555; 
}

.metric-text span {
  font-size: 1.1em;
  color: #444;
}

.main-figure {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.main-figure-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 15px 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.main-figure-container:hover {
  transform: scale(1.03);
  box-shadow: 0.px 4px 12px rgba(0, 0, 0, 0.2);
}

.main-figure-label {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 5px;
}


.revenue-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.revenue-filter label {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.revenue-filter span {
  font-size: 1em;
  color: #555;
  margin-top: 5px;
}


.revenue-filter input[type="range"] {
  margin: 0 10px;
  width: 200px;
}


.toggle-section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.toggle-section button {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  padding: 12px 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.toggle-section button:hover {
  background-color: #0056b3;
}

/* Table Styling */
.fault-revenue-table {
  margin: 0 auto;
  width: 80%;
  text-align: center;
  border-collapse: collapse;
}

.fault-revenue-table h3 {
  margin-bottom: 10px;
  text-align: center;
  font-size: 1.4em;
}

.fault-revenue-table table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}

.fault-revenue-table th {
  background: linear-gradient(to bottom, #4cb050, #6bbd76);
  color: #505050;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.9em;
  border-bottom: 3px solid #585858;
  font-weight: bold;
}

.fault-revenue-table td {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fefefe;
}

.fault-revenue-table td {
  background-color: #fafafa;
  padding: 10px;
  border: 1px solid #ddd;
}

.fault-revenue-table tr:nth-child(even) td {
  background-color: #f9f9f9;
}

.fault-revenue-table tr:hover td {
  background-color: #e9e9e9;
}

.horizontal-slider {
  width: 400px;
  margin: 15px 0;
  padding: 5px 0;
}

.thumb {
  background-color: #000000; 
  border: 2px solid #333;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  top: 0px;
}

.track {
  background-color: #09a53a;
  height: 6px;
  border-radius: 3px;
}

.horizontal-slider:hover .thumb {
  background-color: #696969; 
}

.toggle-and-filter-section {
  background-color: #f9f9f9;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  max-height: 300px; 
  overflow: hidden;
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 0.3s ease, transform 0.3s ease, max-height 0.5s ease; 
}

.toggle-and-filter-section.hidden {
  max-height: 0px;
  opacity: 0.9; 
  transform: scaleY(0.5); 
}

.toggle-filter-button {
  background-color: #f9f9f9;
  color: #333;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 0 auto; 
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.toggle-filter-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

.revenue-filter-inline {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.revenue-filter-inline.hidden {
  opacity: 0;
  transform: translateY(-10px); 
  pointer-events: none;
}

.revenue-filter-inline label {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 5px;
  color: #333;
  text-align: center;
}

.revenue-filter-inline span {
  font-size: 1em;
  color: #555;
  margin-top: 5px;
  text-align: center;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}