/* General Loading Screen Styling */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  z-index: 1000;
  flex-direction: column;
}

.plane-animation {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plane-animation p {
  margin-top: 100px;
  font-size: 35px;
  color: #333;
  text-align: center;
}

/* Plane Styling */
.plane {
  width: 80px;
  height: auto;
  position: absolute;
  left: 0;
  bottom: 110px;
  animation: movePlane 2s linear forwards;
  transform-origin: center center;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #333;
  border-radius: 50%;
  position: absolute;
  bottom: 140px;
  opacity: 0; /* Start invisible */
  animation: fadeInOutDot 1s forwards; /* Increased duration for more visibility */
}

/* Position each dot with increasing left value and delay */
.dot-1 { left: 0%; animation-delay: 0s; }
.dot-2 { left: 5%; animation-delay: 0.1s; }
.dot-3 { left: 10%; animation-delay: 0.2s; }
.dot-4 { left: 15%; animation-delay: 0.3s; }
.dot-5 { left: 20%; animation-delay: 0.4s; }
.dot-6 { left: 25%; animation-delay: 0.5s; }
.dot-7 { left: 30%; animation-delay: 0.6s; }
.dot-8 { left: 35%; animation-delay: 0.7s; }
.dot-9 { left: 40%; animation-delay: 0.8s; }
.dot-10 { left: 45%; animation-delay: 0.9s; }
.dot-11 { left: 50%; animation-delay: 1s; }
.dot-12 { left: 55%; animation-delay: 1.1s; }
.dot-13 { left: 60%; animation-delay: 1.2s; }
.dot-14 { left: 65%; animation-delay: 1.3s; }
.dot-15 { left: 70%; animation-delay: 1.4s; }
.dot-16 { left: 75%; animation-delay: 1.5s; }
.dot-17 { left: 80%; animation-delay: 1.6s; }
.dot-18 { left: 85%; animation-delay: 1.7s; }
.dot-19 { left: 90%; animation-delay: 1.8s; }
.dot-20 { left: 95%; animation-delay: 1.9s; }

/* Dot Fade-in Animation */
@keyframes fadeInOutDot {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Plane Movement */
@keyframes movePlane {
  0% {
    left: 0;
    opacity: 0.2;
  }
  10%{
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  95%{
    opacity: 0.2;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

/* Flash Animation */
.flash {
  width: 30px;
  height: auto;
  position: absolute;
  opacity: 0;
  animation: flashEffect 4s linear infinite;
}

@keyframes flashEffect {
  0%, 15%, 30% {
    opacity: 0;
  }
  32% {
    opacity: 1;
    bottom: 70px;
    transform: translateX(20px) rotate(225deg);
    left: 30%;
  }
  40% {
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}
