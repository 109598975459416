.statistics-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  pointer-events: none; /* Prevents blocking map interactions */
}

.toggle-button {
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-size: 28px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
  pointer-events: auto; /* Allows clicking on the button */
}

.statistics-view {
  width: 100%;
  height: 550px; /* Fixed height for the entire statistics panel */
  background-color: #c0c0c0;
  border-top: 10px solid #ccc;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; /* Prevents content overflow outside the panel */
}

.statistics-view.visible {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  pointer-events: auto; /* Blocks map interactions when visible */
}

.statistics-content {
  padding: 10px;
  height: 90%; /* Use the full height of the container */
  display: flex;
  flex-direction: column;
}

.stat-data-table {
  width: 99%;
  border-collapse: collapse;
  font-size: 11px;
}

.stat-data-table th, .stat-data-table td {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.stat-data-table-container {
  flex-grow: 1;
  overflow-y: auto; 
}

/* StatisticsView.css */
.level-1 {
  background-color: red;
  color: white;
}

.level-2 {
  background-color: orange;
  color: white;
}

.level-3 {
  background-color: yellow;
  color: black; /* Adjust color if needed for contrast */
}
