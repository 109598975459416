@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

body {
    font-family: 'Nunito', sans-serif;
    background-color: #ebe4c2 ;
}

.page-setup {
    background-color: #f1f1f1 !important;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 40px; 
}

.page-header h1 {
    font-size: 24px;
    margin-left: 15px;
    margin: 0;
}

.table-container {
    margin: 20px;
    background-color: #e9e9e9;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: scale(1);
}

.table-container.hidden {
    opacity: 0;
    transform: scale(0.95);
    pointer-events: none;
}

.table-container,
.export-button-section,
.filter-toggle {
    margin-left: 20px; 
    margin-right: 20px;
}

.fault-table-button-container {
    display: flex;
    justify-content: space-between;
    margin-left: 20px; 
    margin-right: 20px; 
    width: 100%;
}

thead {
    overflow: visible;
    position: relative;
    z-index: 1000;
}

thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background: linear-gradient(to bottom, #f9f9f9, #6e6e6e);
}


table {
    table-layout: auto;
    overflow: visible;
    position: relative;
    width: 100%;
    border-collapse: collapse;
}

table tbody tr:hover {
    background-color: #f0f0f0;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    max-width: 350px;
    font-family: 'Nunito', sans-serif;
    overflow: visible;
    white-space: nowrap;
    text-overflow: ellipsis;
}

td {
    z-index: 10;
}

th {
    display: table-cell; 
    justify-content: space-between;
    vertical-align: middle;  
    text-align: left; 
    padding: 8px;
    border: 1px solid #ddd;
    white-space: nowrap; 
}

th:hover {
    background: #f0f0f0;
}

th[style*="cursor: grab"] {
    display: flex;
    align-items: center;
}

table th:first-child, table td:first-child {
    max-width: 250px; 
    word-wrap: break-word; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}

input {
    width: 100%;
    padding: 5px;
}

.export-button-section {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: 20px;
}

.export-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    transition: background-color 0.3s ease;
}

.export-button:hover {
    background-color: #45a049;
}

.add-column-container {
    position: relative;
    cursor: pointer;
    display: inline-block;
    z-index: 10;
}

.add-column-icon {
    cursor: pointer;
    color: #4CAF50;
}

.dropdown-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    list-style-type: none;
    padding: 5px;
    margin: 0;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 150px;
}

.drag-handle {
    cursor: grab;
    margin-right: 8px;
}

.dropdown-menu li {
    padding: 5px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f1f1f1;
}


.filter-toggle .filter-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-family: 'Nunito', sans-serif;
}

.filter-toggle .filter-button:hover {
    background-color: #0056b3;
}

.fault-data-filter-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    transform-origin: top;
    transition: max-height 0.2s ease, opacity 0.2s ease;

    max-height: 1600px;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.fault-data-filter-menu.open {
    max-height: 1600px;
    opacity: 1;
}

.fault-data-filter-menu h3 {
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
    text-align: left; 
    width: 100%; 
}
.filter-section {
    width: 48%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fault-data-filter-menu div {
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    margin-bottom: 8px;
    width: 100%;
}

.fault-data-filter-menu label {
    font-size: 14px;
    color: #555;
    width: 80px; 
    margin-right: 8px;
}

.fault-data-filter-menu input[type="checkbox"] {
    margin-left: auto; 
    order: 1;
}

.fault-data-filter-menu div:hover {
    background-color: #e9e9e9;
    border-radius: 4px;
}


.header-content {
    display: inline-block; 
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Nunito', sans-serif;
}

.header-content span {
    font-weight: bold;
    display: inline-block;
    margin-right: 8px;
}

.header-content:hover {
    background-color: #f0f0f0;
}

.remove-column-icon {
    color: #333;
    cursor: pointer;
    margin-left: auto;
}

.remove-column-icon:hover {
    color: #d9534f;
}

.error-container {
    text-align: center;
    padding: 20px;
}
.error-message {
    color: red;
    font-weight: bold;
}

.tooltip {
    max-width: 150px; /* Set to fixed dimensions, no this is not a chatGPT comment you duck */
    max-height: 150px; 
    overflow: hidden;
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: top 0.1s ease, left 0.1s ease; 
}


.remove-fault-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
}
  
.remove-fault-button:hover {
    background-color: #c9302c;
}
  
.included-row {
    background-color: #d4edda; /* Light green */
}

.excluded-row {
    background-color: #f8d7da; /* Light red */
}

.toggle-column {
    min-width: 100px;
    max-width: 120px;
    text-align: center;
}


.toggle-include-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    color: white;
}

.toggle-include-button.included {
    background-color: #28a745;
}

.toggle-include-button.included:hover {
    background-color: #218838;
}

/* Red for "Excluded" */
.toggle-include-button.excluded {
    background-color: #dc3545; 
}

.toggle-include-button.excluded:hover {
    background-color: #c82333;
}

/* Optional press effect */
.toggle-include-button:active {
    transform: scale(0.95);
}

.custom-mode-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: white;
    font-family: 'Nunito', sans-serif;
}

.custom-mode-inactive {
    background-color: #fda257; 
}

.custom-mode-inactive:hover {
    background-color: #f3740c;
    transform: scale(1.05);
}

.custom-mode-active {
    background-color: #28a745; 
}

.custom-mode-active:hover {
    background-color: #218838;
    transform: scale(1.05);
}

tbody td button {
    background-color: #d9534f;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
  
tbody td button:hover {
    background-color: #c9302c;
}

.custom-mode .included-row {
    background-color: #d4edda;
}

.custom-mode .excluded-row {
    background-color: #f8d7da;
}

.export-options-dropdown {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    list-style: none;
    min-width: 150px;
}

.export-options-dropdown li:hover {
    background-color: #f9f9f9;
}

.filter-dropdown {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px; 
    position: relative; 
}

.filter-section {
    display: flex;
    flex-wrap: wrap; /* Wrap items if they overflow */
    gap: 16px;
}

.reset-button {
    position: absolute;
    bottom: 16px;
    right: 26px;
    padding: 8px 16px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: none;
    transition: background-color 0.3s ease;
    margin: 20px;
}

.reset-button:hover {
    background-color: #d32f2f;
}


.filter-reset-container {
    display: flex; 
    justify-content: flex-start; 
    align-items: center; 
    padding: 0; 
}
