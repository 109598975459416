/* Wrapper for the entire page layout */
.page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #E7F5DC;
  margin-top: 0px;
}

.top-nav-bar {
  width: 100%;
  background-color: #3a3a3a;
  color: #fff;
  padding: 12px 20px;
  position: relative;
  z-index: 10;
}

.dashboard-container {
  padding: 20px;
  margin-top: 20px;
}

.dashboard-main {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}


.dashboard-grid {
  display: grid;
  grid-template-areas: 
    'pie site-data fault-level-breakdown'
    'table table table';
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.kw-input {
  margin-top: 10px;
  margin-bottom: 10px;
}

.kw-input label {
  font-weight: bold;
  margin-right: 10px;
}

.kw-input input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80px;
}

.dashboard-pie-chart {
  grid-area: pie;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.dashboard-site-data {
  grid-area: site-data;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.dashboard-site-data p.small-text {
  font-size: 0.85rem;
  color: #888;
  font-style: italic;
  margin-top: 15px;
}

.dashboard-data-table {
  grid-area: table;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 400px;
}

body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode .top-nav-bar {
  background-color: #222;
  color: #ddd;
}

.dark-mode .metric-highlight-red {
  background-color: #4a0000;
  border-color: #cc0000;
  color: #ffcccc;
}

.dark-mode .metric-combined-orange {
  background-color: #4a2c00;
  border-color: #ff8c42;
  color: #ffdab8;
}

.dark-mode .level-box.yellow {
  background-color: #333300;
  border-color: #999900;
  color: #e6e600;
}

.dark-mode .level-box.orange {
  background-color: #4a2f2a;
  border-color: #cc5200;
  color: #ffa366;
}

.dark-mode .level-box.red {
  background-color: #4a0000;
  border-color: #cc0000;
  color: #ff9999;
}
.dark-mode .dashboard-container {
  background-color: #121212;
}

.dark-mode .dashboard-footer {
  color: #999;
}

.dark-mode,
.dark-mode .dashboard-container,
.dark-mode .dashboard-pie-chart,
.dark-mode .dashboard-site-data,
.dark-mode .dashboard-fault-level-card,
.dark-mode .dashboard-data-table {
  transition: background-color 0.3s, color 0.3s;
}

.dark-mode .dashboard-pie-chart,
.dark-mode .dashboard-site-data,
.dark-mode .dashboard-fault-level-card,
.dark-mode .dashboard-data-table {
  background-color: #1e1e1e;
  color: #e0e0e0;
}

.dashboard-data-table table {
  width: 100%;
  border-collapse: collapse;
}

.dashboard-data-table th,
.dashboard-data-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.dashboard-data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dark-mode .dashboard-data-table tr:nth-child(even) {
  background-color: #333;
}

.dark-mode .dashboard-header h1,
.dark-mode .dashboard-header h2 {
  color: #ffffff;
}

.dark-mode button {
  background-color: #333;
  color: #fff;
}

.dark-mode button:hover {
  background-color: #555;
}

.dark-mode-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  padding-right: 20px;
  gap: 8px;
}

.dashboard-footer {
  text-align: center;
  font-size: 0.85rem;
  color: #666;
  padding: 10px 0;
}


@media (max-width: 1024px) {
  .dashboard-grid {
    grid-template-areas:
      'pie'
      'site-data'
      'fault-level-breakdown'
      'table';
    grid-template-columns: 1fr;
  }

  .dashboard-main {
    margin-left: 0;
    margin-top: 50px;
    padding: 10px;
  }

  .dashboard-site-data,
  .dashboard-pie-chart,
  .dashboard-data-table {
    margin-bottom: 20px;
  }

  .metric-item .metric-value {
    font-size: 1.1rem;
  }
  
  .metric-highlight-red .metric-value,
  .metric-combined-orange p {
    font-size: 1.2rem;
  }
}

.metric-combined-orange {
  background: linear-gradient(to bottom right, #fff5e6, #ffe5cc);
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 10px;
  margin: 8px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.metric-combined-orange p {
  font-weight: bold;
  color: #ff8c42;
  margin: 4px 0;
}

.metric-highlight-red {
  background: linear-gradient(to bottom right, #ffe6e6, #ffd1d1);
  border: 2px solid #ff3333;
  border-radius: 4px;
  padding: 10px;
  margin: 8px 0;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(255, 51, 51, 0.5); 
  transition: box-shadow 0.2s ease-in-out;
}

.metric-highlight-red:hover {
  box-shadow: 0px 8px 12px rgba(255, 51, 51, 0.8); 
}

.metric-highlight-red p {
  font-weight: bold;
  color: #ff3333;
  margin: 0;
}

.metric-highlight-red .metric-value {
  font-size: 1.8rem;
  color: #cc0000;
}

.metric-highlight-red,
.metric-combined-orange {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.metric-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 8px 0;
}

.metric-item {
  flex: 1;
  text-align: center;
}

.metric-item p:first-child::before {
  margin-right: 5px;
}

/* Specific icons for each metric */
.metric-item .kWh-lost::before {
  color: #ff3333;
}

.metric-item .revenue-lost::before {
  color: #ff3333;
}

.metric-item .panels-affected::before {
  color: #ff3333;
}

.metric-item .impacted-kw::before {
  color: #ff8c42;
}

.metric-item .percent-affected::before {
  color: #ff8c42;
}

.metric-item .site-location::before {
  color: #ff8c42;
}

.metric-item .total-panels::before {
  color: #4caf50;
}

.metric-item .total-faults::before {
  color: #ff3333;
}


.metric-item .metric-value {
  font-size: 1.3rem;
  color: #cc0000;
}

.metric-item:hover {
  transform: scale(1.03);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.level-box:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Wave animation effect */
@keyframes waveEffect {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.08); 
    opacity: 0.9;
  }
}

.metric-item.wave {
  animation: waveEffect 0.5s ease-in-out;
}

.metric-item.wave-delay-0 {
  animation-delay: 0s;
}

.metric-item.wave-delay-1 {
  animation-delay: 0.1s;
}

.metric-item.wave-delay-2 {
  animation-delay: 0.2s;
}

.metric-item.wave-delay-3 {
  animation-delay: 0.3s;
}

/* Fault Level Breakdown Card */
.dashboard-fault-level-card {
  grid-area: fault-level-breakdown;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.dashboard-fault-level-card h2 {
  margin-bottom: 10px;
  text-align: center;
}

.levels-summary-vertical {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Level summary boxes */
.level-box {
  padding: 15px 20px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease, max-height 0.4s ease, padding 0.3s ease;
  width: calc(100% - 40px); 
  margin: 10px auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; 
}

.level-box h3 {
  margin: 0;
  font-size: 1.3em;
  font-weight: bold;
}

.level-box .small-text {
  font-size: 0.85em;
  color: gray;
  margin-top: 8px;
}

/* Color coding with softer colors and shadows */
.level-box.yellow {
  background-color: #ffffe0;
  border: 1px solid #e6e600;
  color: #666600;
}

.level-box.orange {
  background-color: #fff3e6;
  border: 1px solid #ffb347;
  color: #ff8c42;
}

.level-box.red {
  background-color: #ffe5e5;
  border: 1px solid #ff6666;
  color: #cc0000;
}

.expanded-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
}

.level-box.expanded .expanded-content {
  max-height: 500px; /* Adjust based on content; ensures smooth transition */
}

.expanded-content table {
  margin-top: 10px;
  width: 100%;
}

.circle-row {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  gap: 5px; 
  padding: 10px 0; 
  width: 100%; /* Ensures row takes full container width */
}

.circle {
  flex: 1; /* Allows circles to resize based on available space */
  max-width: 15px; /* Controls maximum circle size; adjust as needed */
  aspect-ratio: 1; /* Keeps circles perfectly round */
  border-radius: 50%;
}

.green-circle {
  background-color: #4caf50;
}

.red-circle {
  background-color: #f44336;
}

.visual-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.inverter-block-label {
  font-weight: bold;
  width: 100px;
  margin-right: 10px;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.MuiButton-root {
  transition: background-color 0.2s ease;
}

.MuiButton-root:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.MuiButton-root:focus {
  outline: 2px solid rgba(0, 0, 0, 0.3);
}

.row-tooltip {
  position: fixed;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #E7F5DC;
  color: #ebe4c2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-size: 0.9rem;
  z-index: 1000;
  pointer-events: none; 
  transition: opacity 0.2s ease;
}

.row-tooltip.hidden {
  display: none;
}

.dashboard-notes {
  position: fixed;
  top: 200px; /* Same as .notes-tag to align with it */
  right: 20px;
  width: 300px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  z-index: 90;
  transition: max-height 0.4s ease, opacity 0.4s ease, padding 0.4s ease;
}

/* Expanded state for the Notes section */
.dashboard-notes.open {
  max-height: 300px; /* Adjust as needed */
  opacity: 1;
  padding: 15px;
}

.dashboard-notes:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.notes-textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  resize: none;
  margin-bottom: 10px;
}

.save-notes-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #E7F5DC;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.notes-textarea:focus {
  outline: 2px solid #007bff;
  border-color: #0056b3;
}

.save-notes-button:focus {
  outline: 2px solid #0056b3;
}

.save-notes-button:hover {
  background-color: #0056b3;
}

.dark-mode .dashboard-notes {
  background-color: #1e1e1e;
  border-color: #444;
}

.dark-mode .notes-textarea {
  background-color: #333;
  color: #e0e0e0;
  border-color: #555;
}

.dark-mode .save-notes-button {
  background-color: #444;
  color: #e0e0e0;
}

.dark-mode .save-notes-button:hover {
  background-color: #666;
}


@media (max-width: 768px) {
  .dashboard-notes {
    margin: 10px 0;
    padding: 10px;
  }

  .notes-textarea {
    font-size: 14px;
    height: 80px;
  }

  .save-notes-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.notes-tag {
  position: fixed;
  top: 200px; /* Adjust this value to place it directly below the navbar */
  right: 20px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.notes-tag:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Left-pointing arrow style */
.notes-tag .arrow {
  font-size: 1.2em;
  margin-left: 5px;
}

.notes-textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none; /* Disable resizing */
  font-size: 16px;
  font-family: Arial, sans-serif;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fdfdfd;
  color: #333;
}

.notes-textarea:focus {
  outline: none;
  border-color: #007bff; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.notes-buttons {
  display: flex;
  justify-content: space-between; /* Distribute buttons evenly */
  margin-top: 16px;
}

.notes-buttons button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.notes-buttons button:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.notes-buttons button:active {
  transform: translateY(0); /* Reset on click */
}

.notes-buttons button:first-child {
  background-color: #007bff; /* Save button color */
  color: w#E7F5DC;
}

.notes-buttons button:first-child:hover {
  background-color: #0056b3; 
}

.notes-buttons button:last-child {
  background-color: #6c757d;
  color: #E7F5DC;
}

.notes-buttons button:last-child:hover {
  background-color: #5a6268; 
}

.notes-container {
  max-height: 400px; 
  overflow-y: auto; 
  border-radius: 10px; 
  scroll-behavior: smooth;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notes-container::-webkit-scrollbar {
  width: 8px; 
}

.notes-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.notes-container::-webkit-scrollbar-thumb:hover {
  background-color: #bbb; 
}

.styled-textarea {
  width: 100%;
  height: 100px;
  resize: none;
  border-radius: 8px; 
  padding: 8px; 
  border: 1px solid #ccc;
  font-size: 1rem;
  box-sizing: border-box;
}

.notes-modal {
  max-height: 300px;
  overflow-y: auto;
}

.notes-list {
  max-height: 200px;
  overflow-y: auto; 
}

.notes-buttons .MuiButton-startIcon {
  margin-right: 4px; 
}

.info-icon-container {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}

.info-icon {
  font-size: 1.2em;
  font-weight: bold;
  color: #666;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 1px solid #666;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.info-icon:hover {
  background-color: #ddd;
  transform: scale(1.1); 
}

.info-content {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  font-size: 0.9em;
  color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-align: left;
  line-height: 1.4;
  transition: opacity 0.5s ease-in-out;
}

.info-level {
  margin-bottom: 20px;
}

.info-level h4 {
  color: #444;
  margin-bottom: 10px;
}

.info-content table {
  width: 100%;
  border-collapse: collapse;
}

.info-content table td {
  padding: 5px 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.info-content table td:first-child {
  font-weight: bold;
  color: #555;
}

.info-content h3 {
  margin-bottom: 10px;
  color: #222;
}

.level-box {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: opacity 0.3s ease;
}


.info-icon-container:hover ~ .levels-summary-vertical {
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none; 
}


.info-icon-container:hover ~ .info-content {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
