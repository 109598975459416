.main-view {
    flex: 1;
    height: 100vh;
    position: relative;
  }

  .info-window-content {
    max-width: 420px; 
    max-height: 500px;
    padding-bottom: 20px;
  }

  .info-window-content img {
    max-width: 400px;
    height: auto;
    max-height: 500px;
    margin-top: 10px;
  }
  