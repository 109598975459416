.filter-menu {
  background-color: #2a2a2a;
  width: 350px;
  height: 100vh;
  padding: 10px;
  color: e0e0e0;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}


.filter-menu h2 {
  margin-top: 0;
  font-size: 1.3em;
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #444;
}

.filter-menu ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.filter-menu ul li {
  margin: 0;
  padding: 5px 0;
}


.filter-menu::-webkit-scrollbar {
  width: 8px;
}

.filter-menu::-webkit-scrollbar-track {
  background: #444;
}

.filter-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.filter-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Carousel Container Styling */
.carousel-container {
  margin-top: 20px;
  height: 500px; 
  border: 0px solid red;
}

/* Set fixed height for images in the carousel */
.carousel-container img {
  max-height: 180px; /* Set a reasonable height for the images */
  width: auto; /* Make sure the image takes up the full width of the container */
  object-fit: contain; /* Maintain aspect ratio and ensure the image fits within the defined height */
  border: 0px solid green;
  background-color: #444;
  border-radius: 5px;
}

.carousel-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Optional: Styling the captions */
.carousel-caption {
  text-align: center;
  font-size: 14px;
  color: #e0e0e0;
  background-color: rgba(0, 0, 0, 0.7); /* Make the background more opaque for better readability */
  padding: 8px;
  border-radius: 5px;
  width: 90%;
  display: block;
  margin: 10px auto;
  border: 0px solid blue; /*these are here for debugguing */
}

.filter-table-container {
  margin-top: 15px;
  padding: 10px;
  background-color: #3c3c3c;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}


.filter-data-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #555;
  border-radius: 8px;
  overflow: hidden;
}

.filter-data-table th {
  background-color: #3a3a3a;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}

.filter-data-table th, .filter-data-table td {
  padding: 10px;
  text-align: left;
  font-size: 13px;
  color: #e0e0e0;
}

.filter-data-table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.filter-data-table tbody tr:last-child td {
  border-bottom: none;
}

.fault-details {
  margin-top: 15px;
  padding: 10px;
  background-color: #3c3c3c;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.fault-details h3 {
  color: #ebebeb;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
  max-height: 550px;
}

.fault-details p {
  font-size: 0.95em;
  color: #ccc;
  margin: 8px 0;
}

.fault-details img {
  border-radius: 5px;
  margin-top: 10px;
  max-width: 100%;
  height: auto;
  background-color: #444;
}