/* Main container for the entire Home page */
.Home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Fixed positioning for the top bar (HeaderBar and TopNavBar) */
.top-bar-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100; 
  height: 183px;
}

/* Wrapper for the content below the top bars */
.content-wrapper {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  margin-top: 183px;
  height: calc(100vh - 183px); /* Adjust height to fit below top bars */
}

/* Main content that includes Sidebar, MainView, and FilterMenu */
.main-content {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}

/* Sidebar styling */
.sidebar {
  height: 100%;
  width: 200px; /* Fixed width */
  overflow-y: auto; /* Enable scrolling within the sidebar */
  z-index: 50; /* Lower z-index if it overlaps unwanted areas */
  transition: width 0.3s ease;
}

.sidebar.minimized {
  width: 50px; /* Minimized width */
}

.main-content .map-filter-container {
  width: calc(100% - 200px); /* Default width with expanded sidebar */
}

.map-filter-container {
  display: flex;
  flex-grow: 1; /* Allows this container to expand based on available space */
  height: 100%; /* Occupies the remaining height within content-wrapper */
  transition: width 0.3s ease; /* Smooth transition */
  margin-left: 200px;
}

.map-filter-container.expanded {
  margin-left: 50px; /* Align with minimized sidebar */
}


.filter-menu {
  height: 100%; /* Let it take the remaining height in .content-wrapper */
  width: 300px; /* Set a fixed width for consistency */
  overflow-y: auto;
  z-index: 10;
  margin-top: 0; /* Remove any margin */
  padding-top: 183px; /* Push down inside itself to avoid overlap with top bars */
}

.statistics-view {
  max-height: 650px;
}

.statistics-view, .filter-menu {
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .sidebar {
    display: none; /* Hide sidebar on smaller screens */
  }
  .content-wrapper {
    margin-top: 120px; /* Adjust if the top bar is smaller on mobile */
    height: calc(100vh - 120px); /* Adjust height for mobile top bar */
  }
  .map-filter-container {
    width: 100%;
    margin-left: 0; /* Center content when sidebar is hidden */
  }
}
