.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white; /* Adjust as per your theme */
  }
.account-icon-section {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.account-info-button {
  display: flex;
  align-items: center;
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.account-info-button:hover {
  background-color: #45a049;
}

.account-info-button span {
  margin-left: 10px; /* Space between the icon and text */
  font-size: 16px;
  color: white;
}

.account-icon {
  width: 30px; /* Adjust size of the account icon */
  height: auto;
  cursor: pointer;
}