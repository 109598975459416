.header-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #ffffff;
    height: 95px; 
}
  
.logo-section {
    display: flex;
    align-items: center;
    margin-right: auto;
}
  
.logo-section img {
    height: 60px;
    width: auto;
}

.site-logo.hover-effect {
    transition: transform 0.2s ease-in-out;
}

.site-logo.hover-effect:hover {
    transform: scale(0.9);
}
  
.settings-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;  
    margin-left: auto;
    margin-right: 25px;
    position: relative;
    width: 400px;
    height: 75px;
    padding: 10px 0;  
    display: flex;
    justify-content: center;
}

.display-mode {
    position: absolute;
    top: 10px;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}
  
.display-mode p {
    margin: 0;
    font-size: 0.9rem;
}
  
.spinner-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.edit-button {
    background: none;
    border: none;
    color: #324d34;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    gap: 4px;
    max-width: 65px;
    margin-top: 8px;
    margin-bottom: 5px; 
}
  
.edit-button:hover {
    background-color: rgba(50, 77, 52, 0.1);
    text-decoration: underline;
}
  
.account-icon-section {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}
  
.account-info-button {
    display: flex;
    align-items: center;
    background-color: #4cb050 !important;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.account-info-button:hover {
    background-color: #005704 !important;
}
  
.account-info-button span {
    margin-left: 10px;
    font-size: 16px;
    color: white;
}
  
.account-icon {
    width: 30px;
    height: auto;
    cursor: pointer;
}
  
.edit-mode {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
  
.edit-row {
    display: flex;
    align-items: center; 
    gap: 25px; 
    margin: 0;
}

.edit-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-right: 25px;
    margin-top: 2px; 
}

.edit-buttons button:first-child {
    background-color: #4CAF50;
}
    
.edit-buttons button:last-child {
    background-color: white;
    color: #333;
    border: 1px solid #ccc;
    padding: 6px 16px; 
}

.edit-buttons button:last-child:hover {
    background-color: #f5f5f5;
}
  
.input-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: -30px;
}
  
.input-field {
    display: flex;
    align-items: center;
    gap: 10px;
}
  
.edit-row label {
    font-weight: bold;
    white-space: nowrap;
    min-width: 180px; 
}
  
.edit-row input {
    width: 80px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
button {
    background-color: #868686;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
}
  
button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
  
button:hover:not(:disabled) {
    background-color: #767676;
}
  
.error-message {
    color: #ff3333;
    background-color: #ffe6e6;
    padding: 8px;
    border: 1px solid #ff0000;
    border-radius: 4px;
    margin-top: 10px;
    text-align: center;
}

.success-message {
    color: #4caf50;
    background-color: #e8f5e9;
    padding: 8px;
    border: 1px solid #4caf50;
    border-radius: 4px;
    margin-top: 10px;
    text-align: center;
}
  
  
  /* Fade transition */
.fade-enter {
    opacity: 0;
    transform: translateY(-10px);
}
  
.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
}
  
.fade-exit {
    opacity: 1;
    transform: translateY(0);
}
  
.fade-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}
  

@media (max-width: 768px) {
    .header-bar {
        height: auto;
        min-height: 80px;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
    }
  
    .settings-section {
        margin: 15px 0;
        width: 100%;
    }
  
    .edit-row {
        flex-direction: column;
        align-items: flex-start;
    }
  
    .edit-buttons {
        margin-top: 10px;
        width: 100%;
        justify-content: flex-start;
    }
  }