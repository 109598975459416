
.account-info-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .account-info-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .account-info-container p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  
  .account-info-container p strong {
    color: #222;
  }
  
  
  input {
    display: block;
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  p {
    margin-top: 10px;
  }
  
  .account-info-page {
    position: relative;
    min-height: 100vh;
  }
  
  /* Back button in the bottom-left corner */
  .back-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .back-button:hover {
    background-color: #45a049;
  }