/* Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff; 
}

.login-box {
  background-color: #ffffff; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-box h2 {
  text-align: center;
  color: #4a7c59; 
}

.login-box form {
  display: flex;
  flex-direction: column;
}

.login-box .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.login-box .form-group label {
  color: #4a7c59; 
  font-weight: bold;
  margin-bottom: 5px;
}

.login-box .form-group input {
  padding: 10px;
  border: 1px solid #dfe6e2;
  border-radius: 4px;
  width: 100%; 
  max-width: 250px;
  margin: 0 auto;
}

.login-box button {
  width: 100%;
  background-color: #88b77b !important;
  color: white !important;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

.login-box button:hover {
  background-color: #4a7c59 !important;
}

.login-box p {
  text-align: center;
  color: #4a7c59; 
}

.login-box p a {
  color: #88b77b !important; 
  text-decoration: none;
}

.login-box p a:hover {
  text-decoration: underline !important;
}

.login-box .error-message {
  color: #d9534f !important; 
  margin-bottom: 15px;
  text-align: center;
}

.logo {
  display: block;
  margin: 0 auto 20px auto; 
  width: 100px; 
  height: auto; 
}

.login-box .forgot-password {
  background: none !important;
  border: none !important;
  color: #506885 !important; 
  cursor: pointer !important;
  margin-top: 10px;
  margin-left: 60%;
  text-align: right;
  display: block;
  width: auto;
  font-size: 0.65em;
  padding: 5px 10px;
}

.login-box .forgot-password:hover {
  text-decoration: underline !important;
  background-color: hsl(33, 20%, 43%) !important;
  color: #a7b0bb !important;
}

.login-box .success-message {
  color: #3f7c3e !important; 
  margin-bottom: 10px;
  text-align: center;
}

.reset-password-container {
  margin-top: 20px;
  text-align: left;
}

.reset-password-container label {
  display: block;
  color: #4a7c59;
  font-weight: bold;
  margin-bottom: 5px;
}


